import React from 'react'
import { motion } from 'framer-motion'

function SvgCheckList(props) {
  return (
    <svg
      height="100%"
      width="auto"
      viewBox="0 0 2000 1000"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={652.519}
          id="CheckList_svg__a"
          x1={930.8}
          y2={652.519}
          x2={1565.34}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M472.72 934.988c0-22.424 270.819-40.602 604.89-40.602 334.07 0 604.89 18.178 604.89 40.602 0 22.423-270.82 40.601-604.89 40.601-334.071 0-604.89-18.178-604.89-40.601z"
          fill="#3f3d56"
        />
        <path
          d="M826.396 931.508c0-16.658 177.104-30.161 395.574-30.161s395.57 13.503 395.57 30.161c0 16.657-177.1 30.16-395.57 30.16s-395.574-13.503-395.574-30.16z"
          opacity={0.1}
        />

        <path
          d="M694.704 819.835l6.193 71.226 27.871 6.194 6.194-18.581s-10.839-51.097-10.839-60.387l-29.419 1.548zM772.123 770.286s-12.387 30.968-17.032 34.065c-4.645 3.097 13.936 43.355 13.936 43.355l27.871-4.645s-9.291-32.516-1.549-51.097l-23.226-21.678z"
          fill="#fbc5c5"
        />

        <path
          d="M763.607 788.093s-11.613 3.871-16.258 20.903-4.645 18.581-4.645 18.581l7.742 17.032h4.645s7.742 26.323 6.194 35.613c-1.549 9.291 10.838 44.903 32.516 43.355 21.677-1.548 17.032-27.871 17.032-27.871l-10.838-54.194s0-13.935-3.097-13.935-27.871 4.645-27.871 4.645v4.645s-17.807-24-5.42-48.774zM724.123 883.319v-9.29s-32.516 4.645-32.516 9.29v10.839s-27.871 58.839 3.097 58.839 37.161-12.388 37.161-12.388v-17.032s15.484-7.742 15.484-12.387c0-4.645-15.11-45.679-15.11-45.679s-.374 24.002-8.116 17.808z"
          fill="#2f2e41"
        />
        <path
          d="M667.607 105.253s6.194 29.419 6.194 32.516c0 3.097 29.419 17.032 29.419 17.032l26.323-4.645 9.29-27.871s-15.484-23.226-15.484-32.516l-55.742 15.484z"
          opacity={0.975}
          fill="#f5bbbb"
        />
        <path
          d="M637.413 67.317c0-27.365 22.184-49.548 49.549-49.548s49.548 22.183 49.548 49.548c0 27.365-22.183 49.549-49.548 49.549-27.365 0-49.549-22.184-49.549-49.549z"
          fill="#fbc5c5"
        />
        <path
          d="M671.752 125.665s15.984 26.039 39.21 18.297a32.03 32.03 0 0022.72-29.942l76.377 26.846-13.935 113.032 12.387 131.613-9.291 17.033H662.962s-4.645-23.226-6.194-23.226c-1.548 0-4.645-4.645-3.096-6.194 1.548-1.548 4.645-3.097 3.096-7.742-1.548-4.645-3.096-55.742-3.096-55.742L607.22 174.93l64.532-49.265z"
          fill="#d0cde1"
        />
        <path
          d="M612.639 420.35l27.871 37.162s4.645-38.71 10.839-40.259l-15.484-10.838-23.226 13.935zM922.317 390.931s41.807 26.322 37.162 43.355c-4.645 17.032-52.646-29.42-52.646-30.968 0-1.549 15.484-12.387 15.484-12.387z"
          fill="#fbc5c5"
        />
        <path
          d="M662.188 390.931h-3.097l-4.645 21.677s-24.774 21.678-12.387 55.742l24.774 162.581 3.097 188.904 27.871 7.742 30.967-6.194 7.742-252.387s10.839-23.226 6.194-30.968c-4.645-7.742 1.548-6.194 1.548-6.194l32.517 89.807s-10.839 29.419 0 38.71c0 0-1.549 4.645 0 6.193 1.548 1.549 9.29 7.742 6.193 10.839-3.097 3.097-4.645 0-4.645 4.645s-15.484 43.355-9.29 92.904l20.129 30.967 18.58-10.838 34.065-96.001s-7.742-13.935 0-17.032c7.742-3.097 9.29-6.193 6.194-10.839-3.097-4.645-3.097-20.129 1.548-27.871 4.645-7.741 0-20.129 0-20.129l-17.032-156.387s7.742-41.807-18.581-63.484l-4.645-17.032s-38.71 27.871-147.097 4.645zM643.994 27.398s15.731-34.085 45.883-26.22c30.152 7.866 47.194 19.665 48.505 31.463 1.311 11.799-.655 29.497-.655 29.497s-3.277-24.253-24.253-19.009c-20.975 5.244-53.749 1.311-53.749 1.311l-5.244 47.194s-5.899-8.52-12.454-3.277c-6.555 5.244-19.009-50.472 1.967-60.96z"
          fill="#2f2e41"
        />
        <path
          d="M630.446 174.93H607.22s-20.129 17.033-18.581 49.549L577.8 315.834s0 44.903 18.581 77.419l20.129 38.71s7.742-21.677 30.968-18.581l-12.387-32.516v-7.742s-4.645-6.193-4.645-7.742c0-1.548 6.193-1.548 1.548-4.645-4.645-3.097-9.29-34.064-9.29-34.064s1.548-6.194 3.097-7.742c1.548-1.549-1.549-6.194-1.549-6.194l3.097-4.645 20.129-75.871-17.032-57.291zM783.736 136.22l26.323 4.646s15.484 15.484 21.677 34.064l12.388 55.742 20.129 40.258s23.226 23.226 40.258 75.872l24.774 46.451-26.323 15.484s-72.774-85.161-82.064-114.581l-40.258-77.419 3.096-80.517z"
          fill="#d0cde1"
        />
        <motion.g
          animate={{
            y: [0, 10, -10, 0],
          }}
          transition={{ duration: 1, loop: Infinity }}
        >
          <path
            d="M930.8 366.571h634.54v571.897H930.8V366.571z"
            fill="url(#CheckList_svg__a)"
          />

          <path
            d="M922.182 447.773h17.742v425.733h-17.742V447.773z"
            fill="#3f3d56"
          />
          <path
            d="M1321.15 450.673h82.36v2.32h-82.36v-2.32zM1321.15 432.113h83.52v13.92h-83.52v-13.92zM1405.83 872.926h-53.36v-30.161h38.57v2.32h-36.25v25.521h48.72v-14.541h2.32v16.861zM1019.54 499.395h383.97v9.28h-383.97v-9.28zM1019.54 542.316h383.97v9.28h-383.97v-9.28zM1019.54 585.237h383.97v9.281h-383.97v-9.281zM1019.54 628.159h383.97v9.28h-383.97v-9.28zM1019.54 671.08h383.97v9.28h-383.97v-9.28zM1019.54 714.001h383.97v9.28h-383.97v-9.28zM1019.54 756.922h383.97v9.281h-383.97v-9.281zM1019.54 799.844h383.97v9.28h-383.97v-9.28z"
            fill="#f2f2f2"
          />
          <path
            d="M1446.43 504.035c0-8.329 6.76-15.081 15.09-15.081 8.32 0 15.08 6.752 15.08 15.081s-6.76 15.08-15.08 15.08c-8.33 0-15.09-6.751-15.09-15.08zM1446.43 546.956c0-8.329 6.76-15.08 15.09-15.08 8.32 0 15.08 6.751 15.08 15.08s-6.76 15.081-15.08 15.081c-8.33 0-15.09-6.752-15.09-15.081zM1446.43 589.877c0-8.328 6.76-15.08 15.09-15.08 8.32 0 15.08 6.752 15.08 15.08 0 8.329-6.76 15.081-15.08 15.081-8.33 0-15.09-6.752-15.09-15.081zM1446.43 632.799c0-8.329 6.76-15.081 15.09-15.081 8.32 0 15.08 6.752 15.08 15.081 0 8.328-6.76 15.08-15.08 15.08-8.33 0-15.09-6.752-15.09-15.08zM1446.43 675.72c0-8.329 6.76-15.08 15.09-15.08 8.32 0 15.08 6.751 15.08 15.08s-6.76 15.08-15.08 15.08c-8.33 0-15.09-6.751-15.09-15.08zM1446.43 718.641c0-8.328 6.76-15.08 15.09-15.08 8.32 0 15.08 6.752 15.08 15.08 0 8.329-6.76 15.081-15.08 15.081-8.33 0-15.09-6.752-15.09-15.081zM1446.43 761.563c0-8.329 6.76-15.081 15.09-15.081 8.32 0 15.08 6.752 15.08 15.081 0 8.328-6.76 15.08-15.08 15.08-8.33 0-15.09-6.752-15.09-15.08zM1446.43 804.484c0-8.329 6.76-15.081 15.09-15.081 8.32 0 15.08 6.752 15.08 15.081 0 8.328-6.76 15.08-15.08 15.08-8.33 0-15.09-6.752-15.09-15.08zM1379.73 865.866l-13-13.001 1.64-1.64 11.36 11.36 33.98-33.981 1.64 1.641-35.62 35.621z"
            fill="#3f3d56"
          />
        </motion.g>
        <path
          d="M1299.69 889.746c0-2.562 35.84-4.64 80.04-4.64 44.21 0 80.05 2.078 80.05 4.64 0 2.563-35.84 4.64-80.05 4.64-44.2 0-80.04-2.077-80.04-4.64z"
          opacity={0.1}
        />
        <path
          d="M567.366 921.092c-.266-.435-6.543-10.916-8.72-32.68-1.996-19.968-.712-53.625 16.743-100.573 33.068-88.941-7.621-160.703-8.037-161.418l2.008-1.165c.105 10.605 18.478 16.807 47.612 0a207.718 207.718 0 01-8.603 115.779c-33.011 88.789-8.469 130.821-8.217 131.236l-32.786 48.821z"
          fill="#3f3d56"
        />

        <path
          d="M539.355 606.118c0-8.329 6.752-15.081 15.081-15.081 8.328 0 15.08 6.752 15.08 15.081s-6.752 15.08-15.08 15.08c-8.329 0-15.081-6.751-15.081-15.08zM586.917 661.8c0-8.329 6.751-15.081 15.08-15.081s15.08 6.752 15.08 15.081c0 8.328-6.751 15.08-15.08 15.08s-15.08-6.752-15.08-15.08zM554.436 698.921c0-8.329 6.751-15.081 15.08-15.081s15.081 6.752 15.081 15.081c0 8.328-6.752 15.08-15.081 15.08s-15.08-6.752-15.08-15.08zM593.877 730.242c0-8.329 6.752-15.081 15.08-15.081 8.329 0 15.081 6.752 15.081 15.081 0 8.328-6.752 15.08-15.081 15.08-8.328 0-15.08-6.752-15.08-15.08zM542.835 778.963c0-8.329 6.752-15.08 15.081-15.08 8.328 0 15.08 6.751 15.08 15.08s-6.752 15.08-15.08 15.08c-8.329 0-15.081-6.751-15.081-15.08z"
          fill="#35b775"
        />

        <path
          d="M576.476 921.647s-15.08-37.121 30.161-64.962l-30.161 64.962zM557.93 920.974s-6.864-39.475-59.984-39.137l59.984 39.137z"
          fill="#3f3d56"
        />
      </g>
    </svg>
  )
}

export default SvgCheckList
