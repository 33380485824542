import React from 'react'
import Link from '../../utilities/link'
import { withTranslation } from 'react-i18next'

class ProjectForm extends React.Component {
  render() {
    const { t } = this.props
    let lang =
      typeof window !== `undefined`
        ? window.location.pathname.split('/')[1]
        : this.props.i18n.language.split('-')[0]

    return (
      <form
        method="POST"
        name="project"
        netlify-honeypot="bot-field"
        data-netlify="true"
        action={`/${lang}/grazie`}
      >
        <input type="hidden" name="form-name" value="project" />
        <input type="hidden" name="bot-field" />
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.firstName')}</>}
              </label>
              <input className="input" type="text" name="firstname"></input>
            </div>
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.lastName')}</>}
              </label>
              <input className="input" type="text" name="lastname"></input>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.role')}</>}
              </label>
              <input className="input" type="text" name="role"></input>
            </div>
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.companyName')}</>}
              </label>
              <input className="input" type="text" name="companyname"></input>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.phone')}</>}
              </label>
              <input className="input" type="text" name="phonenumber"></input>
            </div>
            <div className="field">
              <label className="label">
                {<>{t('projectFormFields.email')}</>}
              </label>
              <input className="input" type="email" name="email"></input>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">
            {<>{t('projectFormFields.interestedIn')}</>}
          </label>
          <div className="control">
            <div
              className="select"
              name="interestedin"
              placeholder="Select an option"
            >
              <select name="interesse">
                <option>{t('projectFormFields.interestedInOption1')}</option>
                <option>{t('projectFormFields.interestedInOption2')}</option>
                <option>{t('projectFormFields.interestedInOption3')}</option>
                <option>{t('projectFormFields.interestedInOption4')}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">
            {<>{t('projectFormFields.message')}</>}
          </label>
          <div className="control">
            <textarea
              className="textarea"
              name="message"
              placeholder="Textarea"
            ></textarea>
          </div>
        </div>
        <div className="field">
          <label className="label">
            {<>{t('projectFormFields.budget')}</>}
          </label>
          <div className="control">
            <div
              className="select"
              name="budget"
              placeholder="Select an option"
            >
              <select name="budget-disponibile">
                <option>{'< 5.000 EUR'}</option>
                <option>{'< 25.000 EUR'}</option>
                <option>{'< 50.000 EUR'}</option>
                <option>{'< 100.000 EUR'}</option>
                <option>{'< 500.000 EUR'}</option>
                <option>{'> 500.000 EUR'}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">
            {<>{t('projectFormFields.toContactBy')}</>}
          </label>
          <div className="control">
            <div
              className="select"
              name="tocontactby"
              placeholder="Select an option"
            >
              <select name="preferenza-contatto">
                <option>{t('projectFormFields.toContactByOption1')}</option>
                <option>{t('projectFormFields.toContactByOption2')}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <label className="checkbox">
              <input type="checkbox" name="privacy" />{' '}
              {<>{t('projectFormFields.privacy')}</>}{' '}
              <Link to={'/privacy'} style={{ color: '#444' }}>
                Privacy Policy
              </Link>
              .
            </label>
          </div>
        </div>

        <button type="submit" className="button is-dark is-medium is-fullwidth">
          {<>{t('projectFormFields.send')}</>}
        </button>
      </form>
    )
  }
}

export default withTranslation()(ProjectForm)
