import React from 'react'
import { graphql } from 'gatsby'
import SectionForm from '../../../components/common/sectionForm'
import ProjectForm from '../../../components/forms/projectForm'
import SiteNavigation from '../../../components/header/siteNavigation'
import CommonFooter from '../../../components/footer/commonFooter'
import CheckList from '../../../illustrations/CheckList'
import SvgProjectDevelopmentForm from '../../../illustrations/ProjectForm/ProjectDevelopmentForm'
import Seo from '../../../components/seo/seo'
import { useTranslation } from 'react-i18next'

const ProjectFormPage = ({ data }) => {
  const { t } = useTranslation();

  return (
  <>
    <Seo
      title={t('projectForm.seoTitle')}
      description={t('projectForm.seoDescription')}
      seoImage={data.background.childImageSharp.resize.src}
    />
    <SiteNavigation />
    <SectionForm
      animatedIllustration={<SvgProjectDevelopmentForm />}
      illustration={<CheckList />}
      title={<>{t('projectForm.title')}</>}
      description={<>{t('projectForm.description')}</>}
      form={
        <>
          <ProjectForm />
        </>
      }
    />
    <CommonFooter />
  </>
)
}

export default ProjectFormPage

export const contactQuery = graphql`
  query {
    background: file(relativePath: { eq: "heros/home/industrial-electronics.jpg" }) {
      childImageSharp {
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
