import React from 'react'
import Img from 'gatsby-image'

const SectionForm = props => (
  <div className="py-24 md:py-48 is-relative">
    {props.fluid && (
      <Img
        fluid={props.fluid}
        style={{
          position: 'absolute',
          overflow: 'hidden',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          opacity: 1,
        }}
        alt={props.alt}
      />
    )}
    {props.animatedIllustration && (
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          overflow: 'hidden',
          bottom: 0,
          top: 0,
          width: '100%',
        }}
      >
        {props.animatedIllustration}
      </div>
    )}
    <div className="container" style={{ position: 'relative', zIndex: 5 }}>
      <div className="columns">
        <div className="column is-1"></div>
        <div className="column is-4">
          <div className="content is-large">
            <h1
              className="title is-1 is-white"
              style={{
                backgroundImage:
                  'linear-gradient(to top right,#35b775,#2196f3)',
                padding: '15px',
              }}
            >
              {props.title}
            </h1>
          </div>
          <div
            className="content is-large"
            style={{
              backgroundImage: 'linear-gradient(to top right,#ffffff,#f3f3f3)',
              padding: '15px',
            }}
          >
            <p>{props.description}</p>
          </div>
          {props.illustration && (
            <div className="is-mobile">{props.illustration}</div>
          )}
        </div>
        <div className="column is-1"></div>
        <div className="column is-5 is-gradient" style={{ padding: '50px' }}>
          {/* Enter form here, passing it through as a props. Example: form={<><ProjectForm /></>} */}
          {props.form}
        </div>
      </div>
    </div>
  </div>
)

export default SectionForm
